// Migrated
<template lang="pug">
NuxtLayout(v-if="navTabs" name="banner-default")
  .pb-5
    PrettyTabsNav.pretty-tabs-layout-column.pretty-tabs-layout-mxl-row(
      :tabs="navTabs"
      :locale-urls="localeUrlList"
      @on-tab-change="onTabChange"
    )

    template(v-if="activeTab === TABS.home")
      LazySectionConnections
        template(#link="{ key, text }")
          nuxt-link.btn.btn-blue.mt-3.rounded-3.text-transform-none(:to="parseLink(key)") {{ text }}

    template(v-else-if="activeTab === TABS.hotels")
      LazySectionConnectionHotels

    template(v-else-if="activeTab === TABS.trains")
      LazyConnectionsTextSection(:content="content?.preamble")
      LazyConnectionsByTrain.my-4

    template(v-else-if="activeTab === TABS.bedplaces")
      LazyConnectionsTextSection(:content="content?.layovers")
      LazyConnectionsWithLayovers.my-4(
        :list="layoverList",
        scrolling
      )

    template(v-else-if="activeTab === TABS.pickup")
      LazyConnectionsTextSection(:content="content?.pickups")
      LazyConnectionsPickupSpots.my-4

    template(v-else-if="activeTab === TABS.taxi")
      LazyConnectionsTextSection(:content="content?.taxiInfo")
      LazyConnectionsWithLayovers.my-4(:list="taxiList")

    template(v-else-if="activeTab === TABS.parking")
      LazyConnectionsTextSection(:content="content?.parking")
</template>

<script setup>
import {
  CONNECTION_TABS,
  connectionCMSSections as content,
  connectionsNavTabs as navTabList,
  connectionsWithTaxi as taxiList,
  connectionsWithLayovers as layoverList,
} from '@/components/info/connections/connectionUtil'

definePageMeta({
  scrollToTop: false,
})

const { urls: localeURLs } = useLocale()
const localeStore = useLocaleStore()
const rootStore = useRootStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const { public: { images: imagesConfig } } = useRuntimeConfig()

const title = 'Anslutningar'
const subtitle = 'Se våra anslutningar nedan'

const TABS = ref(CONNECTION_TABS)

const localeUrlList = computed(() => {
  return localeURLs?.['info-connectionsSlug']
})

const navTabs = computed(() => {
  return navTabList.map(({ text, key }) => ({
    text,
    to: parseLink(key),
    localeUrlKey: TABS.value?.[key],
  }))
})

const { $isSol } = useNuxtApp()

const { error, data } = await useAsyncData(async () => {
  try {
    if ($isSol) {
      useError({ statusCode: 404, message: 'This page could not be found' })
    }
    const slug = 'anslutning'

    const { data: result } = await apiFetch(`/${localeStore.locale}/staticpage/${encodeURIComponent(slug)}`)

    rootStore.SET_PAGE_BANNER({
      title,
      subtitle,
      type: 'small',
      background: {
        image: {
          url: imagesConfig.fallbackHeroId,
        },
      },
    })

    const tab = findActiveSlugKey(localeURLs['info-connectionsSlug'], route.params)
    const initialTab = TABS.value[tab] || TABS.value.home
    const description = result?.meta_description || subtitle

    return {
      initialTab,
      description,
    }
  } catch (e) {
    throw new Error('Post not found: ' + router.currentRoute.value.fullPath)
  }
})

if (error.value) {
  throw createError({
    message: error.value.message,
    statusCode: 404,
    stack: error.value.stack,
    fatal: true,
  })
}

const { description, initialTab } = data.value

const activeTab = ref(initialTab)

useHead({
  title: () => `${title}`,
  link: [
    {
      rel: 'canonical',
      href: () => `${localeURLs.BASE_URL}${localeURLs.campaign}`,
    },
  ],

  meta: [
    { hid: 'author', name: 'author', content: () => t('siteTitleBrand') },
    {
      hid: 'og:url',
      property: 'og:url',
      content: () => `${localeURLs.BASE_URL}${localeURLs.campaign}`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `${title.value} - ${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: () => description.value,
    },
    {
      hid: 'description',
      name: 'description',
      content: () => description.value,
    },
  ],
})

const onTabChange = (newActiveTab) => {
  activeTab.value = newActiveTab || 'home'
}

const parseLink = (key) => {
  return `${localeURLs?.['info-connections']}${localeUrlList.value?.[key] || ''}`
}
</script>

<style lang="scss">
.connections-info {
  &-icon {
    &, svg {
      height: 1.1em;
      width: 1em;
      min-width: 1em;
      font-size: 1.1rem;

      * {
        fill: $medium-blue;
      }
    }
  }
}
</style>
