export const CONNECTION_TABS = {
  home: 'home',
  hotels: 'hotels',
  trains: 'trains',
  bedplaces: 'bedplaces',
  pickup: 'pickup',
  taxi: 'taxi',
  parking: 'parking',
}
export const connectionsNavTabs = [
  {
    text: 'Anslutningar',
    key: 'home',
  },
  {
    text: 'Övernattningshotell',
    key: 'hotels',
  },
  {
    text: 'Tåganslutningar',
    key: 'trains',
  },
  {
    text: 'Ordinarie upptagningsplatser',
    key: 'pickup',
  },
  {
    text: 'Taxi',
    key: 'taxi',
  },
  {
    text: 'Parkering',
    key: 'parking',
  },
  {
    text: 'Paket med övernattning',
    key: 'bedplaces',
  },
]

export const connectionsByTrain = [
  {
    'city': 'Stockholm',
    'time': '06.20',
    'price': 250,
    'lng': 18.0685808,
    'lat': 59.32932349999999,
  },
  {
    'city': 'Norrköping',
    'time': '07.30',
    'price': 250,
    'lng': 16.192421,
    'lat': 58.587745,
  },
  {
    'city': 'Växjö',
    'time': '08.10',
    'price': 195,
    'lng': 14.8058522,
    'lat': 56.8790044,
  },
  {
    'city': 'Värnamo',
    'time': '08.00',
    'price': 195,
    'lng': 14.0478214,
    'lat': 57.1831605,
  },
  {
    'city': 'Kalmar',
    'time': '07.00',
    'price': 250,
    'lng': 16.356779,
    'lat': 56.6634447,
  },
  {
    'city': 'Karlskrona',
    'time': '07.00',
    'price': 250,
    'lng': 15.5869,
    'lat': 56.161224,
  },
  {
    'city': 'Linköping',
    'time': '08.00',
    'price': 250,
    'lng': 15.6213727,
    'lat': 58.41080700000001,
  },
  {
    'city': 'Nässjö',
    'time': '08.45',
    'price': 250,
    'lng': 14.6967247,
    'lat': 57.65303549999999,
  },
  {
    'city': 'Alvesta',
    'time': '09.30',
    'price': 250,
    'lng': 14.5560006,
    'lat': 56.89921039999999,
  },
  {
    'city': 'Jönköping',
    'time': '08.15',
    'price': 250,
    'lng': 14.1618,
    'lat': 57.7826,
  },
]
export const connectionsWithLayovers = [
  {
    'city': 'Arvika',
    'price': 695,
    'lng': 12.592136,
    'lat': 59.6548534,
  },
  {
    'city': 'Borlänge',
    'price': 895,
    'lng': 15.433969,
    'lat': 60.484304,
  },
  {
    'city': 'Halmstad',
    'price': 695,
    'lng': 12.8577884,
    'lat': 56.6743748,
  },
  {
    'city': 'Falun',
    'price': 895,
    'lng': 15.6355,
    'lat': 60.60646000000001,
  },
  {
    'city': 'Karlskrona',
    'price': 795,
    'lng': 15.5869,
    'lat': 56.161224,
  },
  {
    'city': 'Eskilstuna',
    'price': 895,
    'lng': 16.5098045,
    'lat': 59.37124859999999,
  },
  {
    'city': 'Djurås',
    'price': 965,
    'lng': 15.1318034,
    'lat': 60.56075389999999,
  },
  {
    'city': 'Helsingborg',
    'price': 695,
    'lng': 12.6945121,
    'lat': 56.0464674,
  },
  {
    'city': 'Gränna',
    'price': 715,
    'lng': 14.4700948,
    'lat': 58.02502339999999,
  },
  {
    'city': 'Gävle',
    'price': 895,
    'lng': 17.1412726,
    'lat': 60.6748796,
  },
  {
    'city': 'Hällefors',
    'price': 795,
    'lng': 14.5225692,
    'lat': 59.783688,
  },
  {
    'city': 'Kalmar',
    'price': 695,
    'lng': 16.356779,
    'lat': 56.6634447,
  },
  {
    'city': 'Grängesberg',
    'price': 895,
    'lng': 15.0110618,
    'lat': 60.0785553,
  },
  {
    'city': 'Jönköping',
    'price': 695,
    'lng': 14.1617876,
    'lat': 57.78261370000001,
  },
  {
    'city': 'Karlshamn',
    'price': 795,
    'lng': 14.863073,
    'lat': 56.170303,
  },
  {
    'city': 'Karlstad',
    'price': 595,
    'lng': 13.5114977,
    'lat': 59.4021806,
  },
  {
    'city': 'Laxå',
    'price': 795,
    'lng': 14.538077,
    'lat': 58.88399209999999,
  },
  {
    'city': 'Ludvika',
    'price': 895,
    'lng': 15.191639,
    'lat': 60.152358,
  },
  {
    'city': 'Linköping',
    'price': 695,
    'lng': 15.6213727,
    'lat': 58.41080700000001,
  },
  {
    'city': 'Kristinehamn',
    'price': 775,
    'lng': 14.1089192,
    'lat': 59.3100677,
  },
  {
    'city': 'Leksand',
    'price': 895,
    'lng': 14.9998922,
    'lat': 60.7303082,
  },
  {
    'city': 'Kristianstad',
    'price': 795,
    'lng': 14.1566778,
    'lat': 56.0293936,
  },
  {
    'city': 'Mariestad',
    'price': 695,
    'lng': 13.8213327,
    'lat': 58.7101119,
  },
  {
    'city': 'Lund',
    'price': 695,
    'lng': 13.1910073,
    'lat': 55.7046601,
  },
  {
    'city': 'Nyköping',
    'price': 795,
    'lng': 17.0091593,
    'lat': 58.75284389999999,
  },
  {
    'city': 'Mariannelund',
    'price': 795,
    'lng': 15.5735249,
    'lat': 57.6165963,
  },
  {
    'city': 'Norrköping',
    'price': 765,
    'lng': 16.192421,
    'lat': 58.587745,
  },
  {
    'city': 'Malmö',
    'price': 695,
    'lng': 13.003822,
    'lat': 55.604981,
  },
  {
    'city': 'Mora',
    'price': 895,
    'lng': 14.537003,
    'lat': 61.004878,
  },
  {
    'city': 'Oslo',
    'price': 795,
    'lng': 10.7318202,
    'lat': 59.91271889999999,
  },
  {
    'city': 'Skellefteå',
    'price': 1450,
    'lng': 20.950917,
    'lat': 64.750244,
  },
  {
    'city': 'Sarpsborg',
    'price': 795,
    'lng': 11.1094028,
    'lat': 59.28407290000001,
  },
  {
    'city': 'Nässjö',
    'price': 695,
    'lng': 14.6967247,
    'lat': 57.65303549999999,
  },
  {
    'city': 'Ronneby',
    'price': 795,
    'lng': 15.2760229,
    'lat': 56.210434,
  },
  {
    'city': 'Mellerud',
    'price': 515,
    'lng': 12.4518424,
    'lat': 58.7025681,
  },
  {
    'city': 'Mjölby',
    'price': 695,
    'lng': 15.1335348,
    'lat': 58.3226908,
  },
  {
    'city': 'Rättvik',
    'price': 895,
    'lng': 15.123373,
    'lat': 60.889025,
  },
  {
    'city': 'Stockholm',
    'price': 795,
    'lng': 18.0685808,
    'lat': 59.32932349999999,
  },
  {
    'city': 'Södertälje',
    'price': 795,
    'lng': 17.6201942,
    'lat': 59.197762,
  },
  {
    'city': 'Uppsala',
    'price': 895,
    'lng': 17.6389267,
    'lat': 59.85856380000001,
  },
  {
    'city': 'Vimmerby',
    'price': 795,
    'lng': 15.858857,
    'lat': 57.6690339,
  },
  {
    'city': 'Värnamo',
    'price': 695,
    'lng': 14.0478214,
    'lat': 57.1831605,
  },
  {
    'city': 'Ödeshög',
    'price': 995,
    'lng': 14.6488199,
    'lat': 58.2289961,
  },
  {
    'city': 'Sundsvall',
    'price': 895,
    'lng': 17.306927,
    'lat': 62.390811,
  },
  {
    'city': 'Växjö',
    'price': 675,
    'lng': 14.8058522,
    'lat': 56.8790044,
  },
  {
    'city': 'Örebro',
    'price': 1025,
    'lng': 15.2134105,
    'lat': 59.2752626,
  },
  {
    'city': 'Östersund',
    'price': 1375,
    'lng': 14.6360681,
    'lat': 63.1766832,
  },
  {
    'city': 'Årjäng',
    'price': 695,
    'lng': 12.132717,
    'lat': 59.3891592,
  },
  {
    'city': 'Umeå',
    'price': 1450,
    'lng': 20.2630354,
    'lat': 63.8258471,
  },
  {
    'city': 'Säffle',
    'price': 595,
    'lng': 12.930107,
    'lat': 59.132661,
  },
  {
    'city': 'Åmål',
    'price': 595,
    'lng': 12.6977323,
    'lat': 59.05111699999999,
  },
]
export const connectionsPickupSpots = [
  {
    'time': '06.30',
    'place': 'Borås, Järnvägsstationen',
    'lng': 12.932677,
    'lat': 57.720407,
  },
  {
    'time': '10.30',
    'place': 'Helsingborg, Väla Södra (B)',
    'lng': 12.7586884,
    'lat': 56.090081,
  },
  {
    'time': '06.00',
    'place': 'Ulricehamn, Hallgrens garage',
    'lng': 13.4164513,
    'lat': 57.8050605,
  },
  {
    'time': '07.00',
    'place': 'Landvetter, Landvettermotet',
    'lng': 12.2061454,
    'lat': 57.6861242,
  },
  {
    'time': '06.45',
    'place': 'Bollebygd, Circle-K R-40',
    'lng': 12.541709,
    'lat': 57.663004,
  },
  {
    'time': '10.10',
    'place': 'Ängelholm, McDonalds',
    'lng': 12.8944984,
    'lat': 56.25083369999999,
  },
  {
    'time': '07.40',
    'place': 'Kållered, Busstationen',
    'lng': 12.048420,
    'lat': 57.609919,
  },
  {
    'time': '07.50',
    'place': 'Kungsbacka, Tingberget',
    'lng': 12.0624,
    'lat': 57.48900099999999,
  },
  {
    'time': '11.05',
    'place': 'Löddeköpinge, Center Syd',
    'lng': 12.990607,
    'lat': 55.770158,
  },
  {
    'time': '08.40',
    'place': 'Falkenberg, Stationen',
    'lng': 12.507602,
    'lat': 56.9197476,
  },
  {
    'time': '08.20',
    'place': 'Varberg, Circle-K Varberg Nord',
    'lng': 12.277097,
    'lat': 57.167791,
  },
  {
    'time': '09.45',
    'place': 'Hallandsåsen, Rasta Hallandsåsen',
    'lng': 12.9368472,
    'lat': 56.3890581,
  },
  {
    'time': '11.00',
    'place': 'Landskrona, Järnvägsstationen',
    'lng': 12.8571104,
    'lat': 55.8790871,
  },
  {
    'time': '09.10',
    'place': 'Halmstad, Arenan huvudingången',
    'lng': 12.88965,
    'lat': 56.6741979,
  },
  {
    'time': '11.30',
    'place': 'Malmö, Centralstation',
    'lng': 12.9999228,
    'lat': 55.60906689999999,
  },
  {
    'time': '06.55',
    'place': 'Landvetter, Parkeringen Flygplatsvägen 2 Härryda',
    'lng': 12.2964383,
    'lat': 57.66770880000001,
  },
  {
    'time': '07.30',
    'place': 'Göteborg, Gustav Adolfs torg, Norra Hamngatan',
    'lng': 11.9662388,
    'lat': 57.7066908,
  },
  {
    'time': '07:40',
    'place': 'Mölndal, under Mölndals Bro, västra sidan om E6:an',
    'lng': 12.0169875,
    'lat': 57.6563156,
  },
]
export const connectionsWithTaxi = [
  {
    'city': 'Skara',
    'price': 350,
    'lng': 13.4310231,
    'lat': 58.3847659,
  },
  {
    'city': 'Skövde',
    'price': 350,
    'lng': 13.8404894,
    'lat': 58.3968871,
  },
  {
    'city': 'Uddevalla',
    'price': 350,
    'lng': 11.9342401,
    'lat': 58.3490659,
  },
  {
    'city': 'Vänersborg',
    'price': 350,
    'lng': 12.3134001,
    'lat': 58.377694,
  },
  {
    'city': 'Trollhättan',
    'price': 350,
    'lng': 12.2971801,
    'lat': 58.286777,
  },
  {
    'city': 'Lidköping',
    'price': 350,
    'lng': 13.1571,
    'lat': 58.5035,
  },
  {
    'city': 'Göteborg',
    'lng': 11.9706837,
    'lat': 57.7085398,
    'hidden': true,
  },
]
export const connectionCMSSections = {
  preamble: {
    title: 'Tåganslutningar',
    content: [
      'Vi erbjuder direktanslutning med tåg på våra resor ut i Europa. Ni går på tåget på morgonen i lugn och ro i er hemort och ansluter till resan på Malmö central under förmiddagen. Vid hemkomst kommer ni till Malmö Central igen och tåget tar er sista biten hem. Nedan är några prisexempel.',
    ],
  },
  layovers: {
    title: 'Anslutningspaket med övernattning',
    content: [
      'Vi erbjuder anslutningspaket i samband med köp av någon av våra resor. Du reser dagen innan din avgång för att bo på ett centralt beläget hotell i centrum av Göteborg eller Malmö med närhet till Centralstationen och från vår upptagningsplats. Det innebär att ni i lugn och ro kan åka till Göteborg eller Malmö och tillbringa en eftermiddag i en av Sveriges mest sevärda städer för att nästa dag åka vidare mot nya spännande resmål. När ni efter resan kommer tillbaka så bor ni på samma hotell och åker utvilade hem till respektive hemort. OBS! För flygresor tillkommer transport till flygplatsen.',
      'Priser avser enkel resa, enkelrumstillägg 415kr',
    ],
    pill: 'I paketet ingår: Resa med tåg/buss, en övernattning i dubbelrum i centrala Göteborg, en frukost.',
  },
  pickups: {
    title: 'Ordinarie upptagningsplatser',
    content: [
      'Våra bussar startar från Ulricehamn och tar sedan upp längs med vägen. Bor ni inte i närheten av någon av våra påstigningsplatser så hjälper vi på kontoret er att hitta en anslutningstransport eller bilparkering. På resor norrut erbjuder vi andra påstigningsplatser än de som specificeras nedan.',
    ],
  },
  taxiInfo: {
    title: 'Taxianslutning',
    content: [
      'För att underlätta resan till er upphämtningsplats erbjuder vi taxianslutning på utvalda orter när ni bokar en resa med oss.',
      'Ni blir då hämtade vid dörren och körda till er påstigningsplats i Göteborg för en kostnad av **300 kronor per person** och väg. Priserna är från centralorten. Önskas upphämtning utanför tillkommer en avgift som sätts av taxibolaget.',
    ],
  },

  parking: {
    title: 'Parkering',
    content: [
      '**Landvetter Parkering**',
      'För er som tar bilen ner till Göteborgsområdet går det bra att parkera vid Landvetter Parkering där vi även har påstigning för våra bussresor. Observera att ni måste boka plats själva. [Det görs enklast på deras hemsida](https://landvetterparkering.eu/se/). Uppge kod **rolfsbuss** för 15% rabatt.',
      '**Premium Parking**',
      'Vi samarbetar även med Premium Parking där ni får 10% rabatt på. Ni bokar plats på [Premium Parkings hemsida.](https://premiumparking.se)',
      '**Ulricehamn**',
      'Vi erbjuder även gratis parkering vid Hallgrens Bussgarage i Ulricehamn. Parkeringen finns på Alängsgatan 4 och här kan en bil stå parkerad under alla våra bussresor. Parkeringen behöver ej förbokas. Hallgrens nås på [032-1532090](tel:+46321532090).',
    ],
  },
}
export const connectionCMSHotels = [
  {
    title: 'Övernattningshotell i Göteborg',
    info: 'Bokas via Rolfs Flyg & Buss vid bokning på hemsidan eller genom kundtjänst',
    content: [
      'För er som önskar tillbringa en dag i Göteborg så erbjuder vi övernattning på flera hotell i centrala Göteborg. Här bor ni bra i rum med god standard med gångvägs avstånd från Centralstationen och från vår upptagningsplats. Frukost ingår naturligtvis.',
      'Det centrala läget ger dig närhet till Operan, Ullevi, Scandinavium, Svenska Mässan, Liseberg och stadens övriga kultur- och nöjesliv på bekvämt gångavstånd. Lika nära har du till shoppingstråken i Nordstan och de mysiga kvarteren runt Kungsgatan och Östra Hamngatan.',
      '**575 kr** per person i dubbelrum',
      '**415 kr** enkelrumstillägg',
    ],
    image: 'singlepage/page_assets/anslutningar/anslutningar_goteborg',
    website: null,
  },
  {
    title: 'Övernattningshotell i Malmö',
    info: 'Bokas via Rolfs Flyg & Buss vid bokning på hemsidan eller genom kundtjänst',
    content: [
      'För er som önskar tillbringa en dag i Malmö så erbjuder vi övernattning på flera hotell i centrala Malmö. Här bor ni bra i rum med god standard med gångvägs avstånd från Centralstationen och från vår upptagningsplats. Frukost ingår naturligtvis.',
      'Stortorget med restauranger och nöjen ligger endast några minuters promenad bort.',
      '**295 kr** per person i dubbelrum',
      '**250 kr** enkelrumstillägg',
    ],
    image: 'hotel/ju8fvtii0wiaumv87lff',
    website: 'https://www.scandichotels.se/hotell/sverige/malmo',
  },
  {
    title: 'Övernattningshotell Arlanda',
    info: 'Bokas på egen hand direkt på [hotellets hemsida](//www.scandichotels.se/hotell/sverige/stockholm/scandic-arlandastad)',
    content: [
      'På Arlanda kan vi rekommendera övernattning på Scandic Arlandastad. Hotellet ligger 5 kilometer från Arlanda flygplats. Hotellet erbjuder transfer och parkering, läs mer på deras hemsida för information. <br /> Du som reser med oss får **-10% rabatt** på hotellets aktuella priser.',
      'Du bokar själv hotellet via [Scandics hemsida](//www.scandichotels.se/hotell/sverige/stockholm/scandic-arlandastad) och anger bokningskod **D000052508**',
    ],
    image: 'hotel/cf8ijdrpuri3zs2zkhhl',
    website: '//www.scandichotels.se/hotell/sverige/stockholm/scandic-arlandastad',
  },
  {
    title: 'Landvetter Airport Hotel',
    info: 'Bokas på egen hand direkt på [hotellets hemsida](//www.landvetterairporthotel.se/)',
    content: [
      'För er som skall åka på flygresor från Landvetter så erbjuder Landvetter Airport Hotel förmånliga priser för Rolfs resenärer. Hotellet erbjuder **-15% rabatt** på sina dagspriser och har olika rumstyper efter behov.',
      'Kostnadsfri avbokning eller ombokning fram till 18:00 på ankomstdagen. I priset så ingår frukostbuffé från kl. 04:30 (early bird från 03:30), trådlöst wi-fi, gym, bastu och tillträde till vår relax',
      'Hotellet erbjuder gratis parkering under er hotellvistelse och även längre parkeringsmöjligheter finns till förmånliga priser inklusive fri el-laddning på 5h.',
      'Vänligen kontakta hotellet för mer information.',
      'Hotellet har också en shuttle service dygnet runt för hämtning och lämning på flygplatsen och går på begäran.',
      'Boende bokas på egen hand via telefon på 031-977550 eller mejla till [info@landvetterairporthotel.se](mailto:info@landvetterairporthotel.se) och ni måste uppge att ni åker med **Rolfs Flyg & Buss.** Ni behöver även visa er bokningsbekräftelse vid incheckning. Pris gäller endast efter tillgång.',
    ],
    image: 'singlepage/page_assets/anslutningar/anslutningar_landvetter',
    website: '//www.landvetterairporthotel.se/',
  },
  {
    title: 'Scandic Landvetter',
    info: 'Bokas på egen hand direkt på [hotellets hemsida](//www.scandichotels.se/hotell/sverige/goteborg/scandic-landvetter)',
    content: [
      'På Landvetter erbjuds övernattning på Hotell Scandic Landvetter som ligger precis i anslutning till flygplatsen. Du som reser med oss får **-10% rabatt** på hotellets dagliga priser.',
      'Du bokar själv hotellet via [Scandics hemsida](//www.scandichotels.se/hotell/sverige/goteborg/scandic-landvetter) och anger bokningskod **D000052508**',
    ],
    image: 'singlepage/page_assets/anslutningar/anslutningar_scandic_landvetter',
    website: '//www.scandichotels.se/hotell/sverige/goteborg/scandic-landvetter',
  },
  {
    title: 'Flygplatshotellet Härryda',
    info: 'Bokas på egen hand direkt på [hotellets hemsida](//www.flygplatshotellet.se/)',
    content: [
      'Behöver ni boende inför er flygresa från Landvetter så ger flygplatshotellet i Härryda rabatterade priser på bilparkering och övernattningar i samband med resan. **Uppge Rolfs Flyg & Buss vid bokning.**',
      'Dubbelrum ekonomi  <strong>1100kr</strong> (140cm bred dubbelsäng) <br /> Dubbelrum standard <strong>1250kr</strong> <br /> Enkelrum <strong>950kr</strong>',
      'Parkering inklusive transfer <br /> Långhelg <strong>500kr</strong> <br /> Vecka <strong>595kr</strong> <br /> Två veckor <strong>795kr</strong> <br /> Tre veckor <strong>895kr</strong>',
      'Hotellet erbjuder **10% i rabatt** på ovanstående priser till Rolfs resenärer.',
      'Paketpris på de vanligaste alternativen - ”Rum & Parkering”.',
      'Dubbelrum std & park 1 vecka: <strong>1.650kr</strong> <br /> Dubbelrum eko & park 1 vecka: <strong>1.500kr</strong>',
      'Boknings görs via telefon eller mejl. <br /> [031-888800](tel:+4631-888800), <br /> [Info@Flygplatshotellet.se](mailto:Info@Flygplatshotellet.se)',
    ],
    image: 'singlepage/page_assets/anslutningar/anslutningar_harryda',
    website: '//www.flygplatshotellet.se/',
  },
  {
    title: 'Landvetter BB & Villa Ekegården',
    info: 'Bokas på egen hand direkt på [hotellets hemsida](//www.landvetterbb.se/)',
    content: [
      '<strong>10% rabatt</strong> ges till Rolfs resenärer, använd koden <strong>Rolf2024</strong> på deras hemsida vid bokning.',
      '[www.landvetterbb.se ](//www.landvetterbb.se/) <br /> [www.villaekegarden.se](//www.villaekegarden.se/)',
    ],
    website: '//www.landvetterbb.se/',
  },
  {
    title: 'Övernattning Hotell Nyboholm - Ulricehamn',
    info: 'Bokas på egen hand direkt på [hotellets hemsida](//www.hotellnyboholm.se/)',
    content: [
      'Bokas på egen hand direkt via hotellets hemsida eller på telefon.',
      'För er som tar bilen till Uricehamn kan vi erbjuda prisvärd övernattning på Hotell Nyboholm som ligger cirka 300 meter från Hallgrens bussgarage.',
      'Du som reser med oss får **-10% rabatt** på hotellets aktuella priser vid bokning av hotellrum Ekonomi och Superior.',
      'Boende bokas på egen hand direkt med Hotell Nyboholm med bokningskod: rolfs10',
      'Telefon: [0321-10550](tel:+4632110550)',
    ],
    image: 'singlepage/page_assets/anslutningar/anslutningar_nyboholm',
    website: '//www.hotellnyboholm.se/',
  },
  {
    title: 'Övernattningshotell i Köpenhamn',
    info: 'Bokas på egen hand direkt på [hotellets hemsida](//www.scandichotels.se/hotell/danmark/kopenhamn/scandic-cph-strandpark)',
    content: [
      'För er som önskar tillbringa en natt nära Kastrup flygplats kan vi rekommendera Scandic CPH Strandpark <br /> Du som reser med oss får **-10% rabatt** på hotellets aktuella priser.',
      'Du bokar själv hotellet via [Scandics hemsida](//www.scandichotels.se/hotell/danmark/kopenhamn/scandic-cph-strandpark) och anger bokningskod **D000052508**',

    ],
    image: '/singlepage/page_assets/anslutningar/Scandic_CPH_Strandpark-Exterior-Waterfront2_natvd7',
    website: '//www.scandichotels.se/hotell/danmark/kopenhamn/scandic-cph-strandpark',
  },
]
export const connectionCMSSummary = [
  {
    title: 'Tåganslutningar',
    image: 'singlepage/page_assets/anslutningar/anslutningar_train',
    content: 'Vi erbjuder direktanslutning med tåg på våra resor ut i Europa. Ni går på tåget på morgonen i lugn och ro i er hemort och ansluter till resan på Malmö central under förmiddagen. Vid hemkomst kommer ni till Malmö Central igen och tåget tar er sista biten hem.',
    cta: 'Läs mer',
    link: CONNECTION_TABS.trains,
  },
  {
    title: 'Anslutningspaket med övernattning',
    image: 'singlepage/page_assets/anslutningar/anslutningar_bedplace',
    content: 'Vi erbjuder anslutningspaket i samband med köp av någon av våra resor. Ni bor då på ett centralt beläget hotell i centrum av Göteborg med närhet till Centralstationen och från vår upptagningsplats.',
    cta: 'Läs mer',
    link: CONNECTION_TABS.bedplaces,
  },
  {
    title: 'Taxianslutning',
    image: 'singlepage/page_assets/anslutningar/anslutningar_taxi',
    content: 'På våra resor erbjuder vi anslutning med taxi från utvalda orter. Ni blir hämtade vid dörren och körda till vår upptagningsplats i Göteborg. För tjänsten tillkommer en mindre avgift.',
    cta: 'Läs mer',
    link: CONNECTION_TABS.taxi,
  },
  {
    title: 'Ordinarie upptagningsplatser',
    image: 'singlepage/page_assets/anslutningar/anslutningar_pickup',
    content: 'Våra bussar startar från Ulricehamn och tar sedan upp längs med vägen. Bor ni inte i närheten av någon av våra påstigningsplatser så hjälper vi på kontoret er att hitta en anslutningstransport eller bilparkering.',
    cta: 'Läs mer',
    link: CONNECTION_TABS.pickup,
  },
  {
    title: 'Parkering',
    image: 'singlepage/page_assets/anslutningar/anslutningar_parking',
    content: 'För dig som vill ställa bilen vid Landvetter eller Ulricehamn finns det möjlighet till detta. Här kan du bland annat stå på Landvetters parkering, Landvetter bilhotell eller hos Hallgrens buss.',
    cta: 'Läs mer',
    link: CONNECTION_TABS.parking,
  },
  {
    title: 'Övernattningshotell',
    image: 'singlepage/page_assets/anslutningar/anslutningar_hotels',
    content: 'Läs mer om våra övernattningshotell och vilka alternativ vi erbjuder.',
    cta: 'Läs mer',
    link: CONNECTION_TABS.hotels,
  },
]
